var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"animate-pulse flex overflow-hidden space-x-6"},_vm._l(([1, 2, 3, 4, 5]),function(_,index){return _c('div',{key:index},[_vm._m(0,true)])}),0)]):(_vm.tags && _vm.tags.length > 0)?_c('base-swiper',{attrs:{"options":{
      freeMode: true,
      slidesPerView: 'auto',
      loop: false,
      spaceBetween: 12,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
    }}},_vm._l((_vm.tags),function(ref){
    var _id = ref._id;
    var icon_image_url = ref.icon_image_url;
    var title_vi_vn = ref.title_vi_vn;
return _c('base-swiper-slide',{key:_id,staticClass:"max-w-[86px] flex justify-center"},[_c('div',{on:{"click":function($event){return _vm.handleSelectItemType(_id)}}},[_c('base-link',{attrs:{"to":("/tags/" + _id)}},[_c('base-space',{attrs:{"direction":"vertical","size":"small","align":"center"}},[_c('base-image',{staticClass:"!bg-transparent rounded-full flex items-center justify-center",attrs:{"height":"48","width":"48","src":icon_image_url}}),_vm._v(" "),_c('base-typography-text',{attrs:{"class-name":"line-clamp-1","strong":""}},[_vm._v("\n              "+_vm._s(title_vi_vn)+"\n            ")])],1)],1)],1)])}),1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-20 space-y-4 flex flex-col"},[_c('div',{staticClass:"self-center rounded-full bg-gray-100 h-[48px] w-[48px]"}),_vm._v(" "),_c('div',{staticClass:"h-4 bg-gray-100 rounded"})])}]

export { render, staticRenderFns }