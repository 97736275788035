//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelectItemType(typeId) {
      this.$trackingEvent(this.$EVENT.SHOPPING_SELECT_ITEM_TYPE, {
        type_id: typeId,
      });
    },
  },
};
