
import { Badge as AntBadge } from 'ant-design-vue';
export default {
  render(createElement) {
    const children = Object.keys(this.$slots).map(slot =>
      createElement('template', { slot }, this.$slots[slot]),
    );

    return createElement(
      AntBadge,
      {
        props: this.$attrs,
        on: this.$listeners,
        scopedSlots: this.$scopedSlots,
      },
      children,
    );
  },
};
