import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ed2fbd06&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTypographyTitle: require('/usr/src/app/components/base/Typography/Title.vue').default,BaseIcon: require('/usr/src/app/components/base/Icon/Icon.vue').default,BaseTypographyText: require('/usr/src/app/components/base/Typography/Text.vue').default,BaseTypographyParagraph: require('/usr/src/app/components/base/Typography/Paragraph.vue').default,BaseTooltip: require('/usr/src/app/components/base/Tooltip/Tooltip.vue').default,SharedTagList: require('/usr/src/app/components/shared/TagList/TagList.vue').default,SharedBannerSwiper: require('/usr/src/app/components/shared/BannerSwiper/BannerSwiper.vue').default,SharedDealItemGroupHorizontal: require('/usr/src/app/components/shared/DealItemGroup/Horizontal.vue').default,SharedStoreList: require('/usr/src/app/components/shared/StoreList/StoreList.vue').default,SharedStoreListHorizontal: require('/usr/src/app/components/shared/StoreList/Horizontal.vue').default,BaseBadge: require('/usr/src/app/components/base/Badge/Badge.vue').default,SharedPageFooter: require('/usr/src/app/components/shared/PageFooter/PageFooter.vue').default})
