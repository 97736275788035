//
//
//
//
//
//
//
//

import { Tooltip as AntTooltip } from 'ant-design-vue';

export default {
  components: {
    AntTooltip,
  },
};
