var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.banners && _vm.banners.length > 0)?_c('div',[_c('base-swiper',{attrs:{"options":{
      slidesPerView: 'auto',
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      loop: false,
      spaceBetween: 8,
      autoplay:
        _vm.banners.length > 1
          ? {
              delay: 2500,
              disableOnInteraction: false,
            }
          : false,
    }}},_vm._l((_vm.banners),function(ref,index){
    var internalLink = ref.internalLink;
    var link = ref.link;
    var background_image_url = ref.background_image_url;
    var title_vi_vn = ref.title_vi_vn;
return _c('base-swiper-slide',{key:index,staticClass:"w-[calc(100%-32px)] md:w-[calc(50%-20px)]"},[(!internalLink)?_c('a',{attrs:{"href":link}},[_c('div',{staticClass:"h-[152px] rounded-xl relative bg-cover bg-no-repeat",style:(("background-image: url(" + background_image_url + ")"))},[_c('div',{staticClass:"absolute bottom-0 left-0 right-0 px-4 py-2"},[_c('base-typography-title',{attrs:{"class-name":"line-clamp-2","level":"4"}},[_vm._v("\n              "+_vm._s(title_vi_vn)+"\n            ")])],1)])]):_c('base-link',{attrs:{"to":internalLink}},[_c('div',{staticClass:"h-[152px] rounded-xl relative bg-cover bg-no-repeat",style:(("background-image: url(" + background_image_url + ")"))},[_c('div',{staticClass:"absolute bottom-0 left-0 right-0 px-4 py-2"},[_c('base-typography-title',{attrs:{"class-name":"line-clamp-2","level":"4"}},[_vm._v("\n              "+_vm._s(title_vi_vn)+"\n            ")])],1)])])],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }