import { render, staticRenderFns } from "./Horizontal.vue?vue&type=template&id=78840686&"
import script from "./Horizontal.vue?vue&type=script&lang=js&"
export * from "./Horizontal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedStoreFrame: require('/usr/src/app/components/shared/StoreFrame/StoreFrame.vue').default,BaseTypographyText: require('/usr/src/app/components/base/Typography/Text.vue').default,BaseTypographyParagraph: require('/usr/src/app/components/base/Typography/Paragraph.vue').default,BaseSpace: require('/usr/src/app/components/base/Space/Space.vue').default,BaseLink: require('/usr/src/app/components/base/Link/Link.vue').default,BaseSwiperSlide: require('/usr/src/app/components/base/Swiper/Slide.vue').default,BaseSwiper: require('/usr/src/app/components/base/Swiper/Swiper.vue').default})
