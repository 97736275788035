
import { Divider as AntDivider } from 'ant-design-vue';

const SIZES = {
  SMALL: 'small',
  MIDDLE: 'middle',
  LARGE: 'large',
};

const TYPES = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export default {
  props: {
    size: {
      type: String,
      default: SIZES.LARGE,
    },
  },
  render(createElement) {
    let { type = TYPES.HORIZONTAL } = { ...this.$attrs };

    const { size } = this.$props ?? {};

    return createElement(AntDivider, {
      props: { ...this.$attrs },
      on: this.$listeners,
      class: {
        'my-2': type === TYPES.HORIZONTAL && size === SIZES.SMALL,
        'my-4': type === TYPES.HORIZONTAL && size === SIZES.MIDDLE,
        'my-6': type === TYPES.HORIZONTAL && size === SIZES.LARGE,
        'mx-2': type === TYPES.VERTICAL && size === SIZES.SMALL,
        'mx-4': type === TYPES.VERTICAL && size === SIZES.MIDDLE,
        'mx-6': type === TYPES.VERTICAL && size === SIZES.LARGE,
      },
    });
  },
};
