//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PromoIcon from '@/assets/svg/icons/aha-promo.svg?inline';

export default {
  components: { PromoIcon },
  props: {
    height: String,
    width: String,
    imageUrl: {
      type: String,
    },
    hasPromotion: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    temporarilyClosed: {
      type: Boolean,
      default: false,
    },
  },
};
