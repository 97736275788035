//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import { storeByIdSliceTypes } from '@/store/modules/v2/store';
import { convertStoreList } from '@/plugins/utils';
import { debounce } from '@/plugins/lodash';
import shoppingBagIllustration from '@/assets/svg/illustration/shopping-bag.svg?inline';
import { WEB_IN_APP_AHAMOVE_MOBILE_ID, PAGE_SIZE } from '~/mixins/constances';
import { postMessage, EVENT_CLOSE } from '@/plugins/postMessage';

const LOCATION_NOTIFY_STORAGE_KEY = 'locationNotified';

export default {
  name: 'Home',
  components: {
    shoppingBagIllustration,
  },
  layout: 'defaultV2',
  data: () => ({
    locationTooltipVisible: false,
    isVisible: false,
  }),
  async fetch() {
    const { store } = this.$nuxt.context;

    try {
      await store.dispatch('v2/getTags');
    } catch (error) {
      this?.$sentry.captureException(error);
    }
  },
  computed: {
    ...mapState({
      webInAppConfig: state => state.webInAppConfig,
    }),
    ...mapGetters('v2', [
      'deliveryInfoCoordicate',
      'deliveryAddress',
      'homePageScrollTop',
      'productCount',
      'tags',
      'homePageTags',
      'isTagsLoading',
      'nearByStoresByTagId',
      'isNearByStoresByTagIdLoading',
      'homePageBanners',
      'homePageNearByStores',
      'isNearByStoresLoading',
      'nearByStoresError',
      'dealItemStores',
    ]),
    isShowHeader() {
      return this.webInAppConfig?.id === WEB_IN_APP_AHAMOVE_MOBILE_ID;
    },
  },
  async beforeMount() {
    try {
      if (!this.deliveryInfoCoordicate) {
        this.$router.push(this.withPartnerPath('/delivery-address'));
        return;
      }

      this.showLocationTooltip();

      this.getScreenConfigs(this.deliveryInfoCoordicate);

      this.homePageTags.forEach(async ({ _id }) => {
        const response = await this.getNearByStoresByTagId({
          id: _id,
          params: {
            tags: _id,
            payment_methods: this.webInAppConfig?.paymentMethods ?? undefined,
            ...this.deliveryInfoCoordicate,
          },
        });

        const stores = convertStoreList(response ?? []);

        stores.forEach(store => {
          this.$store.commit(`v2/${storeByIdSliceTypes.SUCCESS}`, {
            id: store._id,
            data: { store: store },
          });
        });
      });

      const nearbyStoresResponse = await this.getNearByStores({
        payment_methods: this.webInAppConfig?.paymentMethods ?? undefined,
        ...this.deliveryInfoCoordicate,
      });

      const nearbyStores = convertStoreList(nearbyStoresResponse ?? []);

      nearbyStores.forEach(store => {
        this.$store.commit(`v2/${storeByIdSliceTypes.SUCCESS}`, {
          id: store._id,
          data: { store: store },
        });
      });

      // await this.getDealItemStores({
      //   ...this.deliveryInfoCoordicate,
      //   size: PAGE_SIZE,
      //   page: 0,
      // });
    } catch (error) {
      this?.$sentry.captureException(error);
    }
  },
  mounted() {
    this.debounceUpdateHomePageScrollTop = debounce(this.updateHomePageScrollTop, 500);

    this.$nextTick(() => {
      this.$refs?.scrollContainerRef?.scrollTo(0, this.homePageScrollTop);
    });
  },
  methods: {
    ...mapActions('v2', [
      'updateHomePageScrollTop',
      'getTags',
      'getScreenConfigs',
      'getNearByStoresByTagId',
      'getNearByStores',
      // 'getDealItemStores',
    ]),
    scrollWatcher(event) {
      this.debounceUpdateHomePageScrollTop(event.target.scrollTop);
    },
    showLocationTooltip() {
      try {
        const locationNotified = JSON.parse(sessionStorage.getItem(LOCATION_NOTIFY_STORAGE_KEY));

        if (!this.deliveryInfoCoordicate || locationNotified) return;

        this.locationTooltipVisible = true;
        sessionStorage.setItem(LOCATION_NOTIFY_STORAGE_KEY, true);

        setTimeout(() => {
          this.locationTooltipVisible = false;
        }, 5000);
      } catch (error) {
        this?.$sentry.captureException(error);
      }
    },
    redirectToDeliveryAddressPage() {
      this.$router.push(this.withPartnerPath('/delivery-address'));
    },
    redirectToSearchPage() {
      this.$router.push(this.withPartnerPath('/search'));
    },
    redirectToCheckoutPage() {
      this.$router.push(this.withPartnerPath('/checkout'));
    },
    closeMiniAppHandler() {
      postMessage(EVENT_CLOSE).catch(err => {
        this.$message.error(err.message);
        this.$sentry.captureException(err);
      });
    },
    async refreshNearByStoresHandler() {
      try {
        const nearbyStoresResponse = await this.getNearByStores({
          payment_methods: this.webInAppConfig?.paymentMethods,
          ...this.deliveryInfoCoordicate,
        });

        const nearbyStores = convertStoreList(nearbyStoresResponse ?? []);

        nearbyStores.forEach(store => {
          this.$store.commit(`v2/${storeByIdSliceTypes.SUCCESS}`, {
            id: store._id,
            data: { store: store },
          });
        });
      } catch (error) {
        console.log('🚀 ~ file: index.vue ~ line 264 ~ refreshNearByStoresHandler ~ error', error);
        this.$sentry.captureException(error);
      }
    },
  },
};
