//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    stores: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    from: {
      type: String,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    tagId: {
      type: [String, Number],
      default: -1,
    },
  },
  methods: {
    handleGoToStoreDetail() {
      const category = this.tags?.find(({ _id }) => _id === Number(this.tagId)) ?? null;

      if (category) {
        if (this.from === 'pages_v2_index') {
          // mean select any store in home page
          this.$trackingEvent(this.$EVENT.SHOPPING_SELECT_STORE, {
            category_type: category.type,
          });
        } else if (this.from === 'pages_v2_tags') {
          // mean select store in category
          this.$trackingEvent(this.$EVENT.SHOPPING_HOME_CATEGORY_SELECT_STORE, {
            category_type: category.type,
          });
        }
      }
    },
  },
};
