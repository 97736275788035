var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"animate-pulse flex overflow-hidden space-x-4"},_vm._l((Array.from(Array(5).keys())),function(_,index){return _c('div',{key:index,staticClass:"space-y-4 w-[128px]"},[_c('div',{staticClass:"rounded-lg bg-gray-100 h-[128px] w-[128px]"}),_vm._v(" "),_vm._m(0,true)])}),0)]):(_vm.stores && _vm.stores.length > 0)?_c('base-swiper',{staticClass:"py-2",attrs:{"options":{
      freeMode: true,
      slidesPerView: 'auto',
      loop: false,
      spaceBetween: 16,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
    }}},_vm._l((_vm.stores),function(ref){
      var _id = ref._id;
      var name = ref.name;
      var address = ref.address;
      var image_url = ref.image_url;
      var distance = ref.distance;
      var friendlyUrl = ref.friendlyUrl;
      var promo_code = ref.promo_code;
      var nextOpenTime = ref.nextOpenTime;
      var is_closed = ref.is_closed;
return _c('base-swiper-slide',{key:_id,staticClass:"max-w-[128px]"},[_c('base-link',{attrs:{"to":friendlyUrl}},[_c('base-space',{attrs:{"direction":"vertical","size":"small","align":"start"}},[_c('shared-store-frame',{attrs:{"height":"128px","width":"128px","image-url":image_url,"has-promotion":!!promo_code,"closed":!!nextOpenTime,"temporarily-closed":is_closed}}),_vm._v(" "),_c('div',[_c('base-typography-paragraph',{attrs:{"class-name":"line-clamp-2"}},[_c('base-typography-text',{attrs:{"strong":""}},[_vm._v(_vm._s(name))])],1),_vm._v(" "),_c('base-typography-paragraph',{attrs:{"class-name":"line-clamp-1"}},[_c('base-typography-text',{attrs:{"type":"secondary"}},[_vm._v(_vm._s(address))])],1),_vm._v(" "),_c('base-typography-paragraph',{attrs:{"class-name":"line-clamp-1"}},[_c('base-typography-text',[_vm._v(_vm._s(distance))])],1)],1)],1)],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"h-4 bg-gray-100 rounded w-3/4"}),_vm._v(" "),_c('div',{staticClass:"h-4 bg-gray-100 rounded w-full"})])}]

export { render, staticRenderFns }